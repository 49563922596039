<style lang="scss" scoped>
  .button-right {
    position: absolute;
    right: 1em;
  }

  @media all and (max-width: 1199px) {
    .button-right {
      position: relative;
      right: 0;
    }
  }
</style>
<template>
  <b-container fluid>
    <div class="filter-bar p-2 mb-3">
      <b-navbar toggleable="xl">
        <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
        <b-collapse id="filter-collapse" is-nav>
          <button @click="resetFilters()" class="filter-btn reset">Reset Filters</button>
          <div class="d-inline-block ml-lg-4">
            Search: <b-form-input v-model="filters.text" class="select" debounce="500"></b-form-input>
          </div>

          <b-form-select
            :options="bookkeepers"
            text-field="fullname"
            value-field="id"
            v-model="filters.bookkeeper"
            plain
            class="select company"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >Select Bookkeeper</b-form-select-option>
            </template>
          </b-form-select>
          <button @click="exportData()" class="filter-btn reset button-right">Export <b-icon icon="download"></b-icon></button>
        </b-collapse>
      </b-navbar>
    </div>

    <b-overlay :show="loading">
      <b-card class="card-border table-card text--black">
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <b-table
          @sort-changed="sortingChanged"
          :items="companies"
          no-local-sorting
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="desc"
          striped
        >
          <template #cell(name)="data">
            <router-link :to="'/admin/company/' + data.item.id" class="text--black u">
              {{ data.item.name }}
            </router-link>
          </template>
          <template #cell(organization)="data">
            {{ data.item.organization }}
          </template>
        </b-table>
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <div class="pl-1 text-left">
          Per page:
          <span
            @click="filters.max = 10"
            :class="filters.max == 10 ? 'active' : ''"
            class="ml-2 link"
          >10</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 25"
            :class="filters.max == 25 ? 'active' : ''"
            class="ml-2 link"
          >25</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 50"
            :class="filters.max == 50 ? 'active' : ''"
            class="ml-2 link"
          >50</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 100"
            :class="filters.max == 100 ? 'active' : ''"
            class="ml-2 link"
          >100</span>
        </div>
      </b-card>
    </b-overlay>

  </b-container>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import AdminService from '@/services/AdminService'

export default {
  data () {
    return {
      filters: {
        max: 25,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        text: '',
        bookkeeper: null,
        active: 1
      },

      totalItems: 0,

      sortBy: 'updated',
      desc: true,

      fields: [
        { key: 'name', sortable: true },
        { key: 'organization', sortable: true },
        { key: 'companytype', label: 'Company Type', sortable: true },
        { key: 'bookkeeper', sortable: true },
        { key: 'updated', sortable: true, sortDirection: 'desc' },
        { key: 'lastsync', label: 'Last QB Sync', sortable: true, sortDirection: 'desc' }
      ],

      companies: [],
      bookkeepers: [],

      loading: false
    }
  },

  mounted () {
    this.search()
    AdminService.getBookkeepers().then(
      (response) => {
        this.bookkeepers = response.data.info
      }
    )
  },

  methods: {
    search () {
      this.loading = true
      this.companies = []
      CompanyService.search({ filters: this.filters }).then(
        (response) => {
          this.companies = response.data.info.results
          this.totalItems = response.data.info.totalResults
        }
      ).finally(() => { this.loading = false })
    },

    resetFilters () {
      this.filters = {
        max: 25,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        text: '',
        bookkeeper: null,
        active: 1
      }

      this.sortBy = 'updated'
      this.desc = true
    },

    exportData () {
      CompanyService.breakdownExport({ filters: this.filters }).then(
        (res) => {
          const a = document.createElement('a')
          a.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + res.data.info
          a.download = 'CompanyList_breakdown.xlsx'
          a.click()
        }
      )
    },

    changePage (page) {
      this.filters.page = page
    },

    sortingChanged (event) {
      this.filters.page = 1
      switch (event.sortBy) {
        case 'name':
          this.filters.order[0].column = 'c.name'
          break
        case 'companytype':
          this.filters.order[0].column = 'ct.id'
          break
        case 'updated':
          this.filters.order[0].column = 'c.updated'
          break
        default:
          this.filters.order[0].column = event.sortBy
          break
      }

      if (event.sortDesc) {
        this.filters.order[0].direction = 'desc'
      } else {
        this.filters.order[0].direction = 'asc'
      }
    }
  },

  watch: {
    filters: {
      handler: async function (n, o) {
        if (n) {
          this.search()
        }
      },
      deep: true
    }
  }
}
</script>
